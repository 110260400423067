import { PageLayout } from '@arcanna/layouts';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { useDPUnifiedContext } from '../DPUnifiedContext/useDPUnifiedContext';
import { useMemo, useState } from 'react';
import { EIcon, Icon, Table } from '@arcanna/generic';
import { getColumns, getGlobalFilterFn } from './DPUnifiedTable.utils';
import { TDPUnifiedTableRowData } from './DPUnifiedTable.types';
import { Breadcrumbs, Stack } from '@mui/material';
import DPUnifiedSaveButton from '../DPUnifiedSaveButton';
import DPUnifiedFilters from '../DPUnifiedFilters';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config } from 'src/config';
import TablePagination from '../../../shared/generic/Table/components/TablePagination';
import JobSelectorComboBox from '@arcanna/pages/Feedback/components/FeedbackHeader/components/JobSelectorComboBox';

function DPUnifiedTable() {
  const { t } = useTranslation(['decisionPointsUnified']);
  const { tableSlice, jobsSlice, handleCheckboxChange, isUpdateLoading } = useDPUnifiedContext();
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 20,
    pageIndex: 0
  });

  const columns = useMemo(
    () => getColumns(jobsSlice.jobInfos, handleCheckboxChange, t),
    [jobsSlice.jobInfos, handleCheckboxChange, t]
  );

  const tableInstance = useReactTable<TDPUnifiedTableRowData>({
    data: tableSlice.DPRows,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: columns as any,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableSorting: true,
    globalFilterFn: getGlobalFilterFn,
    onPaginationChange: setPagination,
    getRowId: (row) => row.fieldName,
    initialState: {
      columnPinning: {
        left: ['fieldName']
      }
    },
    state: {
      pagination
    },
    defaultColumn: {
      minSize: 30
    },
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  return (
    <PageLayout
      isLoading={jobsSlice.isJobInfosLoading || isUpdateLoading}
      breadcrumbContent={
        <Breadcrumbs separator={<Icon name={EIcon.ChevronRight} />} sx={{ marginBottom: 1 }}>
          <Link to={config.routes.jobsAll}>{t('job:aiJobs')}</Link>
          <span>{t('feedback:jobsGroup')}</span>
          <span>{t('decisionPointsUnified:pageTitle')}</span>
        </Breadcrumbs>
      }
      gap={2}
    >
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <JobSelectorComboBox jobIds={jobsSlice.jobIds} />
          <DPUnifiedFilters tableInstance={tableInstance} />
        </Stack>
        <DPUnifiedSaveButton />
      </Stack>
      <Table.component<TDPUnifiedTableRowData>
        isLoading={tableSlice.isDPLoading}
        tableInstance={tableInstance}
        isTableResizable={false}
        noResultsMessageTitle={t('common:noResults')}
        noResultsMessageSubtitle={t('common:adjustSearchCriteria')}
        isStrippedTable
      />
      <TablePagination<TDPUnifiedTableRowData> tableInstance={tableInstance} />
    </PageLayout>
  );
}

export default DPUnifiedTable;
